const FABRIQUE_ZH = {
    FABRIQUE_APP: 'Fabrique App',
    FABRIQUE_APP_1: '设计师驱动的社交化时尚品牌',
    FABRIQUE_APP_2: '全球限量好设计，每周上新',
    FABRIQUE_APP_3: '全球设计师联名合作款触手可及',
    FABRIQUE_APP_4: '为现代女性打造风格独特的完整衣橱',

    FABRIQUE_APP_item_1: '全球限量好设计，每周上新',
    FABRIQUE_APP_item_1_1: '全球设计师联名合作款触手可及',
    FABRIQUE_APP_item_1_2: '为现代女性打造风格独特的完整衣橱',

    FABRIQUE_APP_item_2: '专属Stylist定制化服务',
    FABRIQUE_APP_item_2_1: '专属时尚顾问、顺丰包邮、7天无理由退换',

    FABRIQUE_APP_item_3: '聚合全球设计师动态',
    FABRIQUE_APP_item_3_1: '捕捉更多穿搭灵感',

    FABRIQUE_APP_item_4: 'Fabs用户社区',
    FABRIQUE_APP_item_4_1: '分享个人风格，结交时尚同好',

}

export default FABRIQUE_ZH
