import FABRIQUE_ZH from './zh/fabrique_app'
import DETAIL from './zh/detail'
// const FABRIQUE_EN = require('./en/fabrique_app')
export default {
  MSG: '你好世界',
  'B.HTML': '<b>我是b标签<b>',
  'TABLECOL.NAME': '姓名',
  'TABLECOL.AGE': '年龄',
  'TABLECOL.MONEY': '存款',
  'TABLECOL.BIRTHDAY': '出生日期',
  'PRICE.FORMAT': '{price,number,CNY}',
  'DATE.FORMAT': '{date,date,short}',
  about: '关于我们',
  Contact: '联系我们',
  prospect: '我们的愿景',
  'prospect.slogin': 'To inspire a new pride in self-expression.',
  'prospect.first': '追求美与时尚并不是肤浅的体现',
  'prospect.second': '我们鼓励用户独立思考',
  'prospect.third': '勇敢的进行自我表达',
  business: '我们的业务',
  'business.content': '内容',
  'business.hezuo': '合作',
  'business.shequ': '社区',
  buzhiyuta: '不止于她',
  shortvideo: '光芒系列短视频',
  'glamor-tip-1': '高品质穿搭社区',
  'glamor-tip-2': '明星、博主、时尚达人们在此聚集',
  'glamor-tip-3': '分享时尚穿搭风格、设计艺术美学和生活方式',
  'glamor-box-txt-title1': '精选内容',
  'glamor-box-txt-title2': '搭配推荐',
  'glamor-box-txt-title3': '商城',
  'glamor-box-txt-title4': '活动',
  'glamor-box-txt-main-1': '基于用户兴趣挖掘优质内容，分门别类智能推送',
  'glamor-box-txt-main-2': '明星、博主搭配拆解，穿搭灵感智能推荐',
  'glamor-box-txt-main-3': '提供设计师独家联名商品及精选产品',
  'glamor-box-txt-main-4': '打造最有品质的时尚活动发起平台',
  'glamor-box-txt-main-1-1': '精选全球时尚资讯，',
  'glamor-box-txt-main-1-2': '更有博主大V、时尚爱好者们的优质内容聚合。',
  'glamor-box-txt-main-1-3': '在这里获取一手的潮流趋势与时尚资讯。',
  'glamor-box-txt-main-2-1': '穿搭分解与搭配搜索功能，',
  'glamor-box-txt-main-2-2':
    '由时尚博主与时尚达人亲身示范，提供最时髦的搭配建议和智能推荐。',
  'glamor-box-txt-main-3-1': '与全球最优秀的设计师合作，打造独家限定产品线。',
  'glamor-box-txt-main-3-2': '社区用户可通过每日活跃获取积分，抵现购买商品。',
  'glamor-box-txt-main-4-1':
    '定期举办品牌活动、博主见面、珠宝品鉴、专场观影等关于艺术设计、时尚分享相关的线下体验活动，',
  'glamor-box-txt-main-4-2':
    '用户可以在现场收获志同道合的伙伴，提升生活品质和艺术时尚品味。',
  kol: '优质KOL',
  shequ: '社区',
  'business-neirong-1': '提供具有设计感',
  'business-neirong-2': '及高品质的产品',
  'business-neirong-3': '生产并精选关于时尚和风格的内容',
  'business-hezuo-1': '与全球最优秀的设计师合作打造独家限定产品 ',
  'business-hezuo-2': '链接设计师产业供应链与精准用户',
  'business-shequ-1': '为真实并有表达欲的人提供一个开放的社区平台 ',
  'business-shequ-2': '分享个人风格 结交同好',
  'business-shequ-3': '获得时尚启发',
  solgan: '我们是一个由设计师驱动的社交化时尚品牌',
  desginer: '设计师',
  brandResources: '合作设计师',
  channel: '新媒体矩阵',
  media_1: '小红书',
  media_2: '微信公众号',
  media_3: '抖音',
  media_4: 'B站',
  media_5: '微博',
  value: '品牌价值观',
  'value.slogin': 'Making valuable connections',
  'value.first': '做时尚行业的联结者',
  'value.second': '我们找寻并支持全球最优秀的设计师们',
  'value.third': '为设计师、供应链与消费者建立最有价值的链接',
  'value.four': '我们推崇“设计至上”',
  'value.five': '将好的品味、创意、设计与工艺运用于',
  'value.six': '服装和产品从而带给用户',
  'value.seven': '倡导多样审美',
  'value.eight': '我们鼓励用户为自己的与众不同感到自豪',
  'value.nine': '自信和独立是我们对美的新定义',
  character: '品牌人格',
  'character.first': '我们是高品味的领先者',
  'character.second': '了解短期趋势和永恒设计之间的区别',
  'character.third': '我们分享多元的观点和见解',
  'character.four': '以增进见识并激发时尚和设计的新灵感',
  'character.five': '分享真实的感受、生活和品味',
  'character.six': '这是我们值得信赖的原因',
  guangmangapp: '光芒App',
  Wechat: '微信',
  Android: '安卓/ios',
  IOS: '小程序',
  Tel: '电话',
  Email: '邮箱',
  Weibo: '微博',
  Add: '地址',
  Address: '北京市朝阳区三里屯SOHO办公B',
  zhoubian: '周边',
  chenxuzhi: "陈序之",
  viewMore: '查看更多',
  pinpaiziyuan: '品牌资源',
  ...FABRIQUE_ZH,
  ...DETAIL
};
