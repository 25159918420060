const tips = [
    `Stephane Rolland （斯蒂芬·罗兰）是来自法国的高定大师，法国高级定制和时尚公会正式会员，与香奈儿高定、迪奥高定、圣罗兰等高定成员齐名，Beyonce和Kim Kardashiahn等全球顶流女神的战袍一大半都由他创造。Rolland曾任职Balenciaga创意总监，也是戛纳电影节的官方合作伙伴。Stephane Rolland × Fabrique 联名系列以传承、经典、永恒为主题，造型秉承巴黎的艺术与建筑核心， Stephane Rolland 认为“高定让一个人做自己，勇于与别人不一样”。`,
    `法国设计师Pascal Millet（帕斯卡·米勒）是纪梵希时装屋的设计主将，在John Galliano和Alexander McQueen的指导下，负责高级服装等私人定制系列。Pascal Millet的同名品牌受到优雅女神林心如、人气影星马思纯、卢靖姗以及欧美流行天后Rihanna等一众明星艺人的追捧。Pascal Millet × Fabrique联名系列中，Pascal先生的设计出自他的“Parisian Collection”（巴黎人高定系列），他的作品是 “方便平日穿着的设计，适合喜欢时尚且有自己风格的现代女性”。`,
    `来自比利时的极简大师Jean Paul Knott曾担任 YSL 品牌高级成衣总监长达 12 年，负责“YSL左岸”高级成衣系列的设计。他的同名品牌店 JEANPAULKNOTT 遍布美国、日本、韩国以及欧洲各地，在国际市场中占有一席之地。受Yves Saint Laurent影响，他所设计的衣服褪繁为简，拒绝声色华丽，尊重自我精神。赵薇、林心如、范丞丞、热依扎等多位明星都曾上身演绎。Jean Paul Knott × Fabrique灵感来源于罗马尼亚雕塑家康斯坦丁·布吕尼，该系列将抽象的简洁几何线条和具象艺术的寓意融为一体，颠覆经典，实现了风格与功能的独创。`,
    `美籍华裔设计师Vivienne Tam立足时尚界多年。1998年，Vivienne Tam被提名CFDA “ Perry Ellis ”奖，2008年荣获“影响世界华人大奖”。她所倡导的East-meets-West设计理念深受国际时装界推崇，1994年在纽约创立了个人同名品牌VIVIENNE TAM。Vivienne Tam毕业于香港理工大学设计系，后去到美国帕森斯设计学院继续学习。LadyGaga、巩俐、李玟、水原希子都是她的忠实拥趸。VIVIENNE TAM × Fabrique系列回归东方文化，以中国传统手工刺绣、民族元素图腾作为设计元素，用服饰语言诠释着东方女性的含蓄柔美。`,
    `来自洪都拉斯共和国的设计师Carlos Campos（卡洛斯·坎波斯）是美国时装设计师协会正式成员，曾被提名CFDA/Vogue Fashion Fund并进入决赛。Justin Timberlake和蔡徐坤等横跨音乐、影视界的明星都是他的忠实粉丝。2015年Campos还与洪都拉斯政府合作成立了该国第一所时装大学。Carlos Campos × Fabrique合作系列设计灵感来自建筑诗人Santigo Calatrava的建筑美学。该系列融合了简洁的线条和精致的制作工艺，简约且注重细节，沉稳舒适的色调涵盖整个系列。`,
    `来自瑞士的柯西玛（Cosima）和克里斯塔（Christa）是设计师品牌Ottolinger的创始人，也是Kim Kardashian和Beyonce等明星的御用设计师。Cosima和Christa曾任职Yeezy，并获得国际设计师协会LVMH奖提名。她们的作品现已入驻多家知名买手店。《Vogue》和《Purple》等媒体也对二位的才华不吝赞赏。Cosima Christa × Fabrique联名系列，给女性带来自信的态度和优雅的感受：自然的面料之于安心的清晨，时尚的搭配之于忙碌的一天，色彩丰富的图案之于充满灵感的独特夜晚。`,
    `法国设计师Philippe Perisse（菲利普·佩里斯）先后在⾼定时装屋Scherrer和John Galliano⼯作，曾被欧莱雅邀请加⼊并负责Lancome和Armani彩妆全系列产品开发。他认为服装是女性的铠甲，强调自己只为有力量的独立女性设计服装。Philippe Perisse × Fabrique 联名系列灵感来源于Philippe Perisse品牌本身DNA—— 都市探险家。整个系列体现了军装风格的两个特点：极具功能性与造型感，既呈现女性的魅力，又带有男性的帅气。`,
    `法国设计师Alex Rotin（亚历克斯·罗丁）工作于香奈儿高级定制设计组多年，曾在老佛爷创立的同名品牌KARL LAGERFELD和 KENZO 实习，从法国ESMOD高级时装艺术学院毕业后，在巴黎成立了自己的同名品牌ALEX ROTIN。Alex Rotin × Fabrique 联名系列灵感：“巴黎，光之城，艺术与建筑之城；巴黎人踏着他们的步子，穿过时尚、高定的历史，思考什么是永恒的优雅。”——Alex Rotin`,
    `韩国设计师Lee Seong Dong（李成东）曾斩获多个奖项。他的设计坚持将环保艺术与时尚相融，跨越性别界限，基于艺术、升级、解构和重建等方式设计服装，承包了“性感小野马”金泫雅、“国民初恋”裴秀智的私服穿搭，风格完全不受限。Lee Seong Dong × Fabrique联名系列，通过对称或不对称的结合两个或多个特征，使作品既冲突又和谐，以独特的视角重新诠释平衡、和谐与美学。`,
    `中国时装设计师陈序之曾入围H&M设计大奖、提名LVMH奖及国际羊毛标志奖，入选2017福布斯中国30岁以下精英榜，2018年时装商业评论BoF 500榜单，2020年获得ELLE STYLE AWARDS年度风格设计师。他毕业于伦敦中央圣马丁学院，2015年在伦敦创立了个人品牌XU ZHI。易烊千玺、王俊凯、周冬雨、王嘉尔、童瑶、刘雯等上百位明星都曾穿着过他的作品。XU ZHI × Fabrique联名系列刻画了1970年代的纽约，破坏性的混沌与浪漫并存。年轻艺术家和诗人们期待着自由、狂欢与解脱。`,
    `新加坡新锐设计师Joash Teo 毕业于昆士兰理工大学设计学院，他在2018年大二时，成立了JOTEO。2019获得“国际羊毛标志”奖学金，被著名时尚经纪公司IMG 提名“下一代大奖” ，受邀参加梅赛德斯-奔驰时装节开场秀。2020年被澳大利亚设计学院提名为“年度最佳毕业生”。Joash Teo擅长通过线条与褶皱展现女性优雅，大胆而富有创意。`,
    `知名设计师文瀚（Wen Han）长期专注于高级时装定制，伦敦时装周、纽约时装周均有系列服装展出；Vogue UK、WWD等大量时尚媒体报道其设计作品；Beyonce、Lady Gaga、赵薇、奚梦瑶等多位国际当红明星名模曾穿着拍摄。文瀚2016年毕业于帕森斯设计学院，毕业作品即获得英国著名Fashion Scout Merit Award大奖，同时被CFDA提名为最有潜力毕业生，2017年在纽约创立HANWEN。`,
    `Levi Palmer和Matthew Harding是Palmer//Harding的创始人。二人毕业于伦敦中央圣马丁学院，2017年斩获英国Vogue时尚大奖，《VOGUE》、《ELLE》等时尚媒体曾对他们的设计才华大加赞赏。目前，Palmer//Harding已成为伦敦时装界中“衬衫”的代名词与风向标，米歇尔·奥巴马曾几次穿着他们的衬衫出席活动，范冰冰、佘诗曼等时尚女星也曾穿着上身。`,
    `Francisco Terra曾在Givenchy任职高级设计师，也曾先后在Raf Simons及Carven工作过。2018年被提名法国ANDAM时尚大奖最具创意品牌奖；2017年被提名法国梅赛德斯奔驰设计大奖创意人大奖。Francisco Terra毕业于马兰欧尼设计学院，2015年创立Neith Nyer，蔡徐坤、Bella Hadid等多位明星都曾穿着他的作品。`,
    `Erik Frenken是荷兰著名设计师，2004年获得ELLE Style⼤奖、中央圣马丁/兰蔻创新奖，2012年斩获Marie Claire Prix de la Mode最佳荷兰设计师奖以及“过去十年最佳荷兰设计师”称号。Erik Frenken毕业于伦敦中央圣马丁学院，先后在Viktor & Rolf 、Alberta Ferretti、 Blue Blood、Avelon等著名品牌担任多年的首席设计师及创意总监，之后在荷兰创立FRENKEN。`,
    `Pina Pirozzi和Enzo Della Valle是一对夫妻设计师组，二人于2016年在意大利创立Apnoea。Pina自2012年至今任职FENDI高级设计师，Enzo任职于意大利高级男装Antony Morato。他们2017年首次亮相米兰重量级时装展会WHITE Milano，之后不久，便被邀请参加罗马高定时装周，同时被《Vogue》意大利版选入9月刊主推设计师，2019年Apnoea被意大利国家时装协会评为“意大利最佳新锐品牌”。`,
    `Juan Daels生于阿根廷的设计师世家。他从2017年起，就与法国时装学院、高级定制和时尚联合会、法国高级时装协会开展着长期的艺术合作，他的作品曾多次登上《VOGUE》、《Harper's BAZAAR》等杂志封面，蕾哈娜、查理兹·塞隆、李沁、荷兰王后玛克希玛都曾在公开场合穿着他的作品。他就读于安特卫普皇家艺术学院、伦敦中央圣马丁学院。`,
]
const detail_swiper_title = '2021春夏联名系列'

export default {
    detail_tips: tips,
    detail_swiper_title
}