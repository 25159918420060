const list = [
    {
        back: "https://oss.fabrique.cn/792773db-4080-4910-b3c5-e598c06ba9a3.jpg",
        name: "Stephane Rolland",
        face: "https://oss.fabrique.cn/ed4885a3-e658-47df-82f1-053bdfde00c0.jpg",
        index: 0,
        opus: [
            { src: "https://oss.fabrique.cn/2c86c59f-402a-4db6-a3a2-413cc3557574.jpg" },
            { src: "https://oss.fabrique.cn/79aae9f9-ad3a-49bc-921e-359ffe6eb8f0.jpg" },
            { src: "https://oss.fabrique.cn/a8d0e0dd-b48a-4b37-89ba-602d3abfa9aa.jpg" },
            { src: "https://oss.fabrique.cn/fc9f962b-e9ac-4bc9-b131-cfdb8692088c.jpg" },
            { src: "https://oss.fabrique.cn/91984f1d-9c5b-453c-8972-3e3844568d17.jpg" },
        ],
        logo: "https://oss.fabrique.cn/b67969aa-1c0c-4910-bc40-77123f26b4d4.png",
        pf: 'https://oss.fabrique.cn/6013f2a7-6455-49f7-9818-affe285f3b9e.jpg',
        video: 'https://oss.fabrique.cn/b97035c4-b57c-41b0-abc3-d9df938597e4.mp4'
    },
    {
        back: "https://oss.fabrique.cn/a793b7d7-464d-4a59-9f96-f0ce0a8be4fe.jpg",
        name: "Pascal Millet",
        face: "https://oss.fabrique.cn/d9c7c1e4-1332-43ad-9abb-63418e7292cc.jpg",
        index: 1,
        opus: [
            { src: "https://oss.fabrique.cn/dd94f4ab-be31-4d83-9164-1d79ed82d542.jpg" },
            // {src: "https://oss.fabrique.cn/b052701d-cd62-4259-ab7a-3210d7c110ee.jpg"},
            { src: "https://oss.fabrique.cn/3fcee637-9a81-470f-a617-a70108c615e2.jpg" },
            { src: "https://oss.fabrique.cn/be844c85-7dae-4409-8b88-f172bfb6f86b.jpg" },
            { src: "https://oss.fabrique.cn/ad2a29ce-0eb2-4da5-8ec4-ea07c31185f9.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/Pascal%20Millet/Antibes%E8%BF%9E%E8%BA%AB%E8%A3%99.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/Pascal%20Millet/Capri%E8%BF%9E%E8%BA%AB%E8%A3%99.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/Pascal%20Millet/Ibiza%E8%BF%9E%E8%BA%AB%E8%A3%99.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/Pascal%20Millet/Napoli%E8%BF%9E%E8%BA%AB%E8%A3%99.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/Pascal%20Millet/Portofino%E6%A1%91%E8%9A%95%E4%B8%9D%E5%A4%96%E5%A5%97.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/Pascal%20Millet/Positano%E8%BF%9E%E8%BA%AB%E8%A3%99.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/Pascal%20Millet/Ravello%E8%BF%9E%E8%BA%AB%E8%A3%99.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/Pascal%20Millet/Sanlitun%E6%97%A5%E6%9C%AC%E7%BE%8A%E6%AF%9B%E7%B2%97%E8%8A%B1%E5%91%A2%E5%A4%96%E5%A5%97.jpg" },
            // {src: "https://oss.fabrique.cn/5d66360a-3d7b-4ad6-b577-e0d456428983.jpg"},
        ],
        logo: "https://oss.fabrique.cn/77680082-eddf-4f43-a2cc-fef1fcbd6589.png",
        pf: 'https://oss.fabrique.cn/cb1b026e-e819-45cf-9b82-78d5adfc9cbc.jpg',
        video: 'https://oss.fabrique.cn/27462566-9dc9-45aa-9252-f44e40f902b3.mp4'
    },
    {
        back: "https://oss.fabrique.cn/9d9b8432-efb7-49a8-a695-b4a4512b616a.jpg",
        name: "Jean Paul Knott",
        face: "https://oss.fabrique.cn/d73dec24-0a6c-45f4-99b3-e8c52dd418e4.jpg",
        index: 2,
        opus: [
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/Turquoise%20%E6%97%A5%E6%9C%AC%E9%9D%A2%E6%96%99%E8%BF%9E%E8%BA%AB%E8%A3%99.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/Engelmann%20Spruce%E6%A1%91%E8%9A%95%E4%B8%9D%E8%A1%AC%E8%A1%AB.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/Oak%E6%A1%91%E8%9A%95%E4%B8%9D%E8%A1%AC%E8%A1%AB.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/Obsidian%20%E9%86%8B%E9%85%B8%E8%A5%BF%E8%A3%85.jpg" },
            { src: "https://oss.fabrique.cn/d5adcc82-5ff5-4371-bdbf-5a360790aed2.jpg" },
            { src: "https://oss.fabrique.cn/b52f29a3-6ca1-4950-93d1-84a0b0e93876.jpg" },
            { src: "https://oss.fabrique.cn/d12948f5-70e6-4a46-8248-0b7b07a5d5cd.jpg" },
            { src: "https://oss.fabrique.cn/59ffd211-de86-4a53-894c-d0846df258bb.jpg" },
            { src: "https://oss.fabrique.cn/4524ff4f-d437-4435-8cc5-7a954a96e88e.jpg" },

            { src: "https://oss.fabrique.cn/11103569-46ff-4f57-9449-d66de30e1c82.jpg" },
            { src: "https://oss.fabrique.cn/d3314dda-bb82-4760-a153-e77b16dbc0b4.jpg" },

        ],
        logo: "https://oss.fabrique.cn/7545d7f9-a446-4d4c-b7a3-6a507e3c0c01.png",
        pf: 'https://oss.fabrique.cn/94d075dd-52c4-4243-bcab-a07d21d1fad9.jpg',
        video: 'https://oss.fabrique.cn/6b2cf6e1-acc1-4c2c-9689-b9f2038738a6.mp4'
    },
    {
        back: "https://oss.fabrique.cn/124f6c71-74f8-43af-babd-040b52f597ee.jpg",
        name: "Vivienne Tam",
        face: "https://oss.fabrique.cn/a2542aeb-4378-4158-aea7-9c3aa2adb64e.jpg",
        index: 3,
        opus: [
            // {src: "https://oss.fabrique.cn/a10855cd-1bd9-4b47-bf59-dd821c5838a5.jpg"},
            { src: "https://oss.fabrique.cn/57219cce-08fe-43c9-833d-afce76de8316.jpg" },
            { src: "https://oss.fabrique.cn/05572cd8-671b-4763-91cd-f7accc541ec3.jpg" },
            { src: "https://oss.fabrique.cn/d1631987-4730-4ec6-b0e3-9ae84854a898.jpg" },
            { src: "https://oss.fabrique.cn/a14f557c-6171-41d3-8f9d-225efa3c393d.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/Vivienne%20Tam/Freya%E7%BA%AF%E6%A3%89%E8%A1%AC%E8%A1%AB.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/Vivienne%20Tam/Frigga%20%E5%8D%B0%E8%8A%B1%E8%BF%9E%E8%BA%AB%E8%A3%99.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/Vivienne%20Tam/Layla%E4%B8%8A%E8%A3%85.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/Vivienne%20Tam/Shirley%E5%A4%B9%E5%85%8B.jpg" },
        ],
        logo: "https://oss.fabrique.cn/4b6853b7-d8e2-4ad6-a7ec-c577397e59b8.png",
        pf: 'https://oss.fabrique.cn/6bdad6dc-d8e0-454d-aafc-d3be1ad1f616.jpg',
        video: 'https://oss.fabrique.cn/ef9140ad-43d1-4efd-a24a-189eea03684e.jpg'
    },
    {
        back: "https://oss.fabrique.cn/7ece2c7e-ec53-481d-9674-7787046a35ec.jpg",
        name: "Carlos Campos",
        face: "https://oss.fabrique.cn/b81ea95b-3383-48f6-903c-211408f68a08.jpg",
        index: 4,
        opus: [
            { src: "https://oss.fabrique.cn/639acb00-37bc-461f-892f-85db7d85668c.jpg" },
            { src: "https://oss.fabrique.cn/b44aafef-168f-4321-b60f-659f57ec4180.jpg" },
            { src: "https://oss.fabrique.cn/61a918de-a763-4e1c-9379-28eab9668437.jpg" },
            { src: "https://oss.fabrique.cn/1a8d0e4e-acc5-4f29-98b8-10c3602ab9ce.jpg" },
            { src: "https://oss.fabrique.cn/64f341c5-80e0-472c-b1b7-306f2c346658.jpg" },
            { src: "https://oss.fabrique.cn/8e8df93f-9dbc-4647-8389-74adc5b8164b.jpg" }
        ],
        logo: "https://oss.fabrique.cn/4fdcc740-1e0d-4520-a0aa-358fd1546728.png",
        pf: 'https://oss.fabrique.cn/c1edb628-15d4-47de-a6e9-d5ecd2bd8904.jpg',
        video: 'https://oss.fabrique.cn/d46f1686-e065-4c63-8770-0f4f32f8e5c8.mp4'
    },
    {
        back: "https://oss.fabrique.cn/993c7d55-b8a9-40ff-865e-58a8afab29eb.jpg",
        name: "Cosima Christa",
        face: "https://oss.fabrique.cn/81abee06-533a-4ab7-8248-1ae4f08f34a4.jpg",
        index: 5,
        opus: [
            { src: "https://oss.fabrique.cn/8474b9bf-d928-415f-8bb6-b7b7cdacb293.jpg" },
            { src: "https://oss.fabrique.cn/80462737-ace1-41cd-8838-ac12433e3e19.jpg" },
            { src: "https://oss.fabrique.cn/04c9436e-3f42-4805-abd2-1355e7bcef2d.jpg" },
            { src: "https://oss.fabrique.cn/7fe381d6-5043-4222-b28a-5c61b3ca18ee.jpg" },
            { src: "https://oss.fabrique.cn/90a59cd5-ec00-4433-8507-c2d84d9b5200.jpg" },
            { src: "https://oss.fabrique.cn/f2f8b020-e74c-4fd3-a525-d10879ceafc8.jpg" },
            { src: "https://oss.fabrique.cn/86f6ee77-527d-4fa5-ad61-413d7e007afa.jpg" },
            { src: "https://oss.fabrique.cn/704becbb-ee7b-4402-8cd2-249b60355eec.jpg" },
            { src: "https://oss.fabrique.cn/f0667e0e-e591-4004-bebc-860c0dbf9198.jpg" },
            { src: "https://oss.fabrique.cn/aed53419-924e-4266-a07e-f8b7bc33a6f2.jpg" },
            { src: "https://oss.fabrique.cn/ee09cc68-c4e8-4ace-9fef-f894b0674da6.jpg" },
        ],
        logo: "https://oss.fabrique.cn/1b3cdba3-e9c2-423d-a883-044cae8341d1.png",
        pf: 'https://oss.fabrique.cn/6c33320d-3750-4dc6-88df-3b8128e1f60a.jpg',
        video: 'https://oss.fabrique.cn/771a627c-a08a-4439-8e7e-6c3299a7c5cd.mp4'
    },
    {
        back: "https://oss.fabrique.cn/958b4744-f066-4294-916b-c1b3fcc77a2b.jpg",
        name: "Philippe Perisse",
        face: "https://oss.fabrique.cn/a9f7b88a-e6b4-478b-86d6-a20e3de673b1.jpg",
        index: 6,
        opus: [
            { src: "https://oss.fabrique.cn/d3e34941-bf09-448a-bcff-0bac15deaf29.jpg" },
            { src: "https://oss.fabrique.cn/85a31f80-423e-4913-ba85-9d150746e765.jpg" },
            { src: "https://oss.fabrique.cn/ebda8206-4f28-4242-869d-a5f9de7486d4.jpg" },
            { src: "https://oss.fabrique.cn/853df571-9267-4d2c-82e0-6c9703d61926.jpg" },
            { src: "https://oss.fabrique.cn/105008ed-f701-4ca8-869c-3575138b0edd.jpg" },
        ],
        logo: "https://oss.fabrique.cn/c14932d9-844b-4a3a-a14e-ef44158747fc.png",
        pf: 'https://oss.fabrique.cn/1c974821-540d-4448-b62d-0cb417c2508f.jpg',
        video: 'https://oss.fabrique.cn/c4c133c4-db76-4783-89eb-c89ab0f116db.mp4'
    },
    {
        back: "https://oss.fabrique.cn/1ead944d-549a-4df6-a042-7a1ee670a281.jpg",
        name: "Alex Rotin",
        face: "https://oss.fabrique.cn/622d042a-fc8f-4c77-adb4-3fede5cf6093.jpg",
        index: 7,
        opus: [
            // {src: "https://oss.fabrique.cn/3c368926-a5c7-4752-ad31-1375719ad81e.jpg"},
            { src: "https://oss.fabrique.cn/1a60573b-2fe1-4ad5-88bc-ebcde2e727be.jpg" },
            { src: "https://oss.fabrique.cn/b59b7495-6e48-44f7-8850-6cc4fa9896e7.jpg" },
            { src: "https://oss.fabrique.cn/e5de9bcc-4a78-414a-8e17-36a6e74c2f65.jpg" },
            { src: "https://oss.fabrique.cn/d038dff6-0bdf-4953-9b97-91b0a426a3ea.jpg" },
        ],
        logo: "https://oss.fabrique.cn/c8b4aa50-c31e-4fef-a5b8-f81d0bedaedd.png",
        pf: 'https://oss.fabrique.cn/ee4e9212-690f-4010-bcf9-6bd8aa2f7c32.jpg',
        video: 'https://oss.fabrique.cn/0b43063c-52eb-4123-96e9-d302c2f6e6ae.mp4'
    },
    {
        back: "https://oss.fabrique.cn/9fce6f2f-3f4f-4b2b-92e1-d6091e619203.jpg",
        name: "Lee Seong Dong",
        face: "https://oss.fabrique.cn/eede5ed0-5b00-4eb4-a55d-cb5c453d5459.jpg",
        index: 8,
        opus: [
            { src: "https://oss.fabrique.cn/25cdfc41-802c-4eda-808a-8629d0fe9f42.jpg" },
            { src: "https://oss.fabrique.cn/209c8066-7229-4d5f-b57a-97c5d36e7743.jpg" },
            { src: "https://oss.fabrique.cn/dad64fea-aaa1-4ad7-8061-e74c6dd68f7a.jpg" },
            { src: "https://oss.fabrique.cn/b1d8f058-3cb2-4405-a0ed-eb7a15066031.jpg" },
            { src: "https://oss.fabrique.cn/44c4fb2e-0c0f-419e-b4ad-fffe9a6a16f0.jpg" },
            { src: "https://oss.fabrique.cn/ae78a7b1-fbef-4596-9378-c632bcc5e30e.jpg" },
            { src: "https://oss.fabrique.cn/8878cf73-9564-4f37-8471-651e8b6feaa0.jpg" },
            { src: "https://oss.fabrique.cn/8ddcab8a-5b03-45d2-b6e9-1953f15f9d2b.jpg" },
            { src: "https://oss.fabrique.cn/b24f4900-8473-4e6b-a5bf-07943736ed27.jpg" },
            { src: "https://oss.fabrique.cn/8e49d8c3-f731-4106-964a-04fc16fb4a53.jpg" },
            { src: "https://oss.fabrique.cn/346d19e4-d541-4d9d-8e37-1e0bfca1cea3.jpg" },
            { src: "https://oss.fabrique.cn/f8361b75-6e4d-4747-9b30-aaa124b5850c.jpg" },
            { src: "https://oss.fabrique.cn/f306731f-9612-491b-a2a0-cd9ed39dc1d2.jpg" },
            { src: "https://oss.fabrique.cn/f8aa6ad7-c6de-4267-9f42-053c6abfb1db.jpg" },
            { src: "https://oss.fabrique.cn/67d56401-20a4-4a1a-9f43-379e9cb8c650.jpg" },
        ],
        logo: "https://oss.fabrique.cn/81edda8b-fe77-4e21-bde7-dc7ff912d87f.png",
        pf: 'https://oss.fabrique.cn/1c6ef120-7ca1-4e52-946b-6aa506c152ca.jpg',
        video: 'https://oss.fabrique.cn/a4f08824-fcf9-4eed-98f3-895b6a0c9b19.mp4'
    },
    {
        back: "https://oss.fabrique.cn/f1977e88-1350-4140-94ff-65a50b4ba05a.jpg",
        name: "XUZHI",
        face: "https://oss.fabrique.cn/ae0fe13a-f329-480d-80bd-cea39dfe077b.jpg",
        index: 9,
        opus: [
            { src: "https://oss.fabrique.cn/adf16580-5dda-4f0a-92b8-e69d2eb882de.jpg" },
            { src: "https://oss.fabrique.cn/a28feceb-e164-4a62-a1f4-def9dfe2464c.jpg" },
            // {src: "https://oss.fabrique.cn/6dc0ea8c-8e64-43f6-84f3-89c37f808365.jpg"},
            { src: "https://oss.fabrique.cn/dc218ea2-ab7a-4c49-936e-4be2c84c3121.jpg" },
            { src: "https://oss.fabrique.cn/325f54e2-56a7-4e1c-aa0e-d70506c6f511.jpg" },
            // {src: "https://oss.fabrique.cn/532578da-8bc2-496a-8494-0bc178e17919.jpg"},
            { src: "https://oss.fabrique.cn/56a7cec4-d1eb-4381-9a77-daa7e45df0ed.jpg" },
            { src: "https://oss.fabrique.cn/c8567b85-691f-4efd-81e7-945767369a5c.jpg" },
        ],
        logo: "https://oss.fabrique.cn/cd9b5b6b-77c6-44ae-aa71-9fd2e8290d6d.png",
        pf: 'https://oss.fabrique.cn/d7b970a8-47b2-4698-b012-2ace60d32781.jpg',
        video: 'https://oss.fabrique.cn/882a3d3b-cc05-4a8e-9d3d-f898ef1af37a.mp4'
    },
    {
        back: "https://fabrique.oss-cn-qingdao.aliyuncs.com/Joash%20Teo/%E7%94%BB%E6%9D%BF%201%20%E5%89%AF%E6%9C%AC%204-100.jpg",
        name: "Joash Teo",
        face: "https://fabrique.oss-cn-qingdao.aliyuncs.com/Joash%20Teo/JOTEO.jpg",
        index: 10,
        opus: [
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/Joash%20Teo/Elysees%E6%97%A5%E6%9C%AC%E7%BE%8A%E6%AF%9B%E6%89%93%E8%A3%A5%E5%A4%96%E5%A5%97.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/Joash%20Teo/Grand%20Palais%E6%97%A5%E6%9C%AC%E4%B8%89%E9%86%8B%E9%85%B8%E8%BF%9E%E8%BA%AB%E8%A3%99.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/Joash%20Teo/Marais%E6%84%8F%E5%A4%A7%E5%88%A9%E7%BE%8E%E4%B8%BD%E8%AF%BA%E5%85%A8%E7%BE%8A%E6%AF%9B%E6%89%93%E8%A3%A5%E8%A5%BF%E8%A3%85.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/Joash%20Teo/Petit%20Palais%20%E6%97%A5%E6%9C%AC%E4%B8%89%E9%86%8B%E9%85%B8%E4%B8%8A%E8%A3%85.jpg" },
        ],
        logo: "",
        pf: 'https://fabrique.oss-cn-qingdao.aliyuncs.com/Joash%20Teo/JOTEO.jpg',
        video: 'https://fabrique.oss-cn-qingdao.aliyuncs.com/Joash%20Teo/Joash%20Teo%E7%9A%841920'
    },
    {
        back: "https://fabrique.oss-cn-qingdao.aliyuncs.com/HANWEN/%E7%94%BB%E6%9D%BF%201%20%E5%89%AF%E6%9C%AC%205-100.jpg",
        name: "HANWEN",
        face: "https://fabrique.oss-cn-qingdao.aliyuncs.com/HANWEN/HANWEN.jpg",
        index: 11,
        opus: [
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/HANWEN/Black%20Iris%20%E6%97%A5%E6%9C%AC%E9%9D%A2%E6%96%99%E8%BF%9E%E8%BA%AB%E8%A3%99.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/HANWEN/Daisy%E6%97%A5%E6%9C%AC%E9%9D%A2%E6%96%99%E8%A5%BF%E8%A3%85.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/HANWEN/Eucalyptus%E6%97%A5%E6%9C%AC%E9%9D%A2%E6%96%99%E9%95%BF%E8%A3%A4.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/HANWEN/Peony%E6%97%A5%E6%9C%AC%E9%9D%A2%E6%96%99%E8%A5%BF%E8%A3%85.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/HANWEN/Tulip%E6%97%A5%E6%9C%AC%E9%9D%A2%E6%96%99%E8%A5%BF%E8%A3%85.jpg" },
        ],
        logo: "",
        pf: 'https://fabrique.oss-cn-qingdao.aliyuncs.com/HANWEN/HANWEN.jpg',
        video: 'https://fabrique.oss-cn-qingdao.aliyuncs.com/HANWEN/hanwen-1920.jpg'
    },
    {
        back: "https://fabrique.oss-cn-qingdao.aliyuncs.com/palmerharding/%E7%94%BB%E6%9D%BF%201-100%201.jpg",
        name: "palmer//harding",
        face: "https://fabrique.oss-cn-qingdao.aliyuncs.com/palmerharding/Palmer%3A%3AHarding.jpg",
        index: 12,
        opus: [
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/palmerharding/Julia%E8%A1%AC%E8%A1%AB%E8%BF%9E%E8%A1%A3%E8%A3%99.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/palmerharding/Nana-B%E6%97%A5%E6%9C%AC%E9%9D%A2%E6%96%99%E8%A1%AC%E8%A1%AB.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/palmerharding/Nana-G%E6%97%A5%E6%9C%AC%E9%9D%A2%E6%96%99%E8%A1%AC%E8%A1%AB.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/palmerharding/Nana-P%E6%97%A5%E6%9C%AC%E9%9D%A2%E6%96%99%E8%A1%AC%E8%A1%AB.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/palmerharding/Transform%E8%A1%AC%E8%A1%AB.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/palmerharding/Nana%E6%97%A5%E6%9C%AC%E9%9D%A2%E6%96%99%E8%A1%AC%E8%A1%AB%E8%BF%9E%E8%A1%A3%E8%A3%99.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/palmerharding/Spicy%E6%97%A5%E6%9C%AC%E9%9D%A2%E6%96%99%E8%A1%AC%E8%A1%AB.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/palmerharding/Nana-W%E8%A1%AC%E8%A1%AB.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/palmerharding/Squared%E5%8D%8A%E8%A3%99.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/palmerharding/Strapped%E5%8D%83%E9%B8%9F%E6%A0%BC%E7%BA%B9%E8%A3%A4%E5%AD%90.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/palmerharding/Strapped%E5%8D%83%E9%B8%9F%E6%A0%BC%E7%BA%B9%E8%A5%BF%E8%A3%85.jpg" },
        ],
        logo: "",
        pf: 'https://fabrique.oss-cn-qingdao.aliyuncs.com/palmerharding/Palmer%3A%3AHarding.jpg',
        video: 'https://fabrique.oss-cn-qingdao.aliyuncs.com/palmerharding/Palmer%3A%3AHarding.mp4'
    },
    {
        back: "https://fabrique.oss-cn-qingdao.aliyuncs.com/FranciscoTerra/%E7%94%BB%E6%9D%BF%201%20%E5%89%AF%E6%9C%AC-100.jpg",
        name: "Francisco Terra",
        face: "https://fabrique.oss-cn-qingdao.aliyuncs.com/FranciscoTerra/Francisco%20Terra.jpg",
        index: 13,
        opus: [
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/FranciscoTerra/Woody%E7%89%9B%E4%BB%94%E5%A4%B9%E5%85%8B.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/FranciscoTerra/Andy%E7%89%9B%E4%BB%94%E5%8D%8A%E8%A3%99.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/FranciscoTerra/Bo%20Peep%E4%B8%8A%E8%A1%A3.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/FranciscoTerra/Buttercup%E7%89%9B%E4%BB%94%E8%A3%A4.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/FranciscoTerra/Bonnie%E6%84%8F%E5%A4%A7%E5%88%A9%E7%BE%8E%E4%B8%BD%E8%AF%BA%E7%B2%BE%E7%BA%BA%E7%BE%8A%E6%AF%9B%E8%A5%BF%E8%A3%85.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/FranciscoTerra/Gaby%E6%84%8F%E5%A4%A7%E5%88%A9%E7%BE%8E%E4%B8%BD%E8%AF%BA%E7%B2%BE%E7%BA%BA%E7%BE%8A%E6%AF%9B%E8%A5%BF%E8%A3%85.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/FranciscoTerra/Jessie%E7%BA%AF%E7%BE%8A%E6%AF%9B%E9%92%88%E7%BB%87%E8%A1%AB.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/FranciscoTerra/Trixie%E6%A1%91%E8%9A%95%E4%B8%9D%E4%B8%9D%E7%BB%92%E8%BF%9E%E8%BA%AB%E8%A3%99.jpg" },
        ],
        logo: "",
        pf: 'https://fabrique.oss-cn-qingdao.aliyuncs.com/FranciscoTerra/Francisco%20Terra.jpg',
        video: 'https://fabrique.oss-cn-qingdao.aliyuncs.com/FranciscoTerra/Francisco%C2%A0Terra.mp4'
    },
    {
        back: "https://fabrique.oss-cn-qingdao.aliyuncs.com/ErikFrenken/%E7%94%BB%E6%9D%BF%201%20%E5%89%AF%E6%9C%AC%202-100.jpg",
        name: "Erik Frenken",
        face: "https://fabrique.oss-cn-qingdao.aliyuncs.com/ErikFrenken/Erik%20Frenken.jpg",
        index: 14,
        opus: [
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/ErikFrenken/Fluid-B%E7%9C%9F%E4%B8%9D%E9%A3%8E%E8%A1%A3.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/ErikFrenken/Fluid-P%E7%9C%9F%E4%B8%9D%E9%A3%8E%E8%A1%A3.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/ErikFrenken/Knot-W%E8%A1%AC%E8%A1%AB.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/ErikFrenken/Knot%E8%A1%AC%E8%A1%AB%E8%A3%99.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/ErikFrenken/Ruffled-B%E8%BF%9E%E8%BA%AB%E8%A3%99.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/ErikFrenken/Ruffled-P%E8%BF%9E%E8%BA%AB%E8%A3%99.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/ErikFrenken/Wrap-G%E7%B2%BE%E7%BA%BA%E7%BE%8A%E6%AF%9B%E8%A5%BF%E8%A3%85.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/ErikFrenken/Wrap%E7%B2%BE%E7%BA%BA%E7%BE%8A%E6%AF%9B%E8%A5%BF%E8%A3%85.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/ErikFrenken/Dipped%E5%90%8A%E6%9F%93%E8%A1%AC%E8%A1%AB.jpg" },
        ],
        logo: "",
        pf: 'https://fabrique.oss-cn-qingdao.aliyuncs.com/ErikFrenken/Erik%20Frenken.jpg',
        video: 'https://fabrique.oss-cn-qingdao.aliyuncs.com/ErikFrenken/Erik%C2%A0Frenken.mp4'
    },
    {
        back: "https://fabrique.oss-cn-qingdao.aliyuncs.com/PinaEnzo/%E7%94%BB%E6%9D%BF%201%20%E5%89%AF%E6%9C%AC%203-100.jpg",
        name: "Pina Enzo",
        face: "https://fabrique.oss-cn-qingdao.aliyuncs.com/PinaEnzo/Pina%20Enzo.jpg",
        index: 15,
        opus: [
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/PinaEnzo/Padua-W%E5%8D%B0%E8%8A%B1%E8%BF%9E%E8%BA%AB%E8%A3%99.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/PinaEnzo/Sonya%E6%9D%A1%E7%BA%B9%E8%BF%9E%E8%BA%AB%E8%A3%99.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/PinaEnzo/Kaara-B%E6%A0%BC%E7%BA%B9%E8%BF%9E%E8%BA%AB%E8%A3%99.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/PinaEnzo/Kaara-W%E5%8D%B0%E8%8A%B1%E8%BF%9E%E8%BA%AB%E8%A3%99.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/PinaEnzo/Padua-B%E6%A0%BC%E7%BA%B9%E8%BF%9E%E8%BA%AB%E8%A3%99.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/PinaEnzo/Aeryn%E4%B8%8A%E8%A1%A3.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/PinaEnzo/Cristal%E5%A4%B9%E5%85%8B.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/PinaEnzo/Nyota%E4%B8%8A%E8%A1%A3.jpg" },
        ],
        logo: "",
        pf: 'https://fabrique.oss-cn-qingdao.aliyuncs.com/PinaEnzo/Pina%20Enzo.jpg',
        video: 'https://fabrique.oss-cn-qingdao.aliyuncs.com/PinaEnzo/Pina%C2%A0Enzo.mp4'
    },
    {
        back: "https://fabrique.oss-cn-qingdao.aliyuncs.com/JuanDaels/%E7%94%BB%E6%9D%BF%201%20%E5%89%AF%E6%9C%AC%206-100.jpg",
        name: "Juan Daels",
        face: "https://fabrique.oss-cn-qingdao.aliyuncs.com/JuanDaels/Juan%20Daels.jpg",
        index: 16,
        opus: [
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/JuanDaels/Ana%E6%8B%BC%E6%8E%A5%E8%BF%9E%E8%BA%AB%E8%A3%99.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/JuanDaels/Camila%E6%A1%91%E8%9A%95%E4%B8%9D%E4%B8%8A%E8%A3%85.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/JuanDaels/Catalina%E8%BF%9E%E8%BA%AB%E8%A3%99.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/JuanDaels/Emilia%E6%A1%91%E8%9A%95%E4%B8%9D%E8%A5%BF%E8%A3%85.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/JuanDaels/Lena%E8%A5%BF%E8%A3%85.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/JuanDaels/Nadia%E9%95%BF%E8%A3%A4.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/JuanDaels/Nora%E5%8D%8A%E8%A3%99.jpg" },
            { src: "https://fabrique.oss-cn-qingdao.aliyuncs.com/JuanDaels/Sofia%E6%96%97%E7%AF%B7%E8%BF%9E%E8%BA%AB%E8%A3%99.jpg" },
        ],
        logo: "",
        pf: 'https://fabrique.oss-cn-qingdao.aliyuncs.com/JuanDaels/Juan%20Daels.jpg',
        video: 'https://fabrique.oss-cn-qingdao.aliyuncs.com/JuanDaels/JUAN%201920'
    }
]

export default list
